input {
  display: block;
  /* margin: 20px 0; */
}

::placeholder {
  color:lightgray !important;
}

.spcl-underline-wrap {
  position: relative;
  display: inline-block;
}

.spcl-underline:before {
	content: "";
	position: absolute;
  bottom: 0;
  left: 20px;
	width: calc(100% - 40px);
	border-bottom: 2px solid #f9a748;
}

.spcl-underline-left:before {
	content: "";
	position: absolute;
  bottom: 0;
  left: 0;
	width: calc(100% - 20px);
	border-bottom: 2px solid #f9a748;
}

.button-like-link {
  background:none!important;
  color:inherit;
  border:none;
  padding:0!important;
  font: inherit;
  cursor: pointer;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.fade-exit {
  opacity: 0;
}

.set-width {
  max-width: 1360px!important;
}

.set-height {
  min-height: calc(100vh - 410px);
}

.container {
  margin: 0 20px;
}

.navBar-hover {
  border-bottom: 4px solid white;
}

.navBar-hover:hover {
  border-bottom: 4px solid #f9a748;
}

.co-clr {
  background: #0B1937;
}

.co-purple-clr {
  color: #0B1937;
}

.co-gold-clr {
  color: #f9a748;
}

.scrollbar-primary::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #000013;
  background-color: #363f61;
}

.price-wrapper {
  position: relative;
	display: inline-block;
}

.price-slash {
  position: absolute;
	border-top: 5px solid #f44336;
	-webkit-transform: rotate(-16deg);
	transform: rotate(-16deg);
	margin-top: 38px;
	display: inline-block;
	width: 126%;
	left: -17px;
}

@media (max-width:576px){
  .navbar-nav {
    flex-direction: row;
  }
}